import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ourglobalnetwork',
  templateUrl: './ourglobalnetwork.component.html',
  styleUrls: ['./ourglobalnetwork.component.css']
})
export class OurglobalnetworkComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    window.scrollTo(0, 0);
  }

}
