import { Component, OnInit } from '@angular/core';
import * as Survey from "survey-angular";

@Component({
  selector: 'app-view-form',
  templateUrl: './view-form.component.html',
  styleUrls: ['./view-form.component.css']
})
export class ViewFormComponent implements OnInit {

  // json = {
  //   "pages": [
  //    {
  //     "name": "page1",
  //     "elements": [
  //      {
  //       "type": "text",
  //       "name": "question1",
  //       "title": "First name",
  //       "isRequired": true
  //      },
  //      {
  //       "type": "text",
  //       "name": "question2",
  //       "title": "Last name"
  //      },
  //      {
  //       "type": "text",
  //       "name": "question3",
  //       "title": "Business email"
  //      },
  //      {
  //       "type": "text",
  //       "name": "question4",
  //       "title": "Company "
  //      },
  //      {
  //       "type": "text",
  //       "name": "question5",
  //       "title": "Phone"
  //      }
  //     ],
  //     "title": "SURVEY",
  //     "description": "To download this report, please complete the form below"
  //    }
  //   ]
  //  };

  constructor() { }

  ngOnInit() {
    // var surveyModel = new Survey.Model(this.json);
    // Survey.SurveyWindowNG.render("surveyElement", {
    //   model: surveyModel,
    //   isExpanded: true
    // });
  }

}
