import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-platform',
  templateUrl: './platform.component.html',
  styleUrls: ['./platform.component.css']
})
export class PlatformComponent implements OnInit {
  memberPortalUrl = environment.memberPortalUrl;

  constructor() { }

  ngOnInit() {
    window.scrollTo(0, 0);
  }

}
