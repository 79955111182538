import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SurveyFormsRoutingModule } from './survey-forms-routing.module';
// import { ViewFormComponent } from './view-form/view-form.component';
import { SurveyComponent } from '../../components/shared/survey/survey.component';
import { FormsModule } from '@angular/forms';


@NgModule({
  declarations: [
    //ViewFormComponent
  ],
  imports: [
    CommonModule,
    SurveyFormsRoutingModule,
    FormsModule
  ],

})
export class SurveyFormsModule { }
