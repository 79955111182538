import { Component, OnInit } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { PlatformLocation } from '@angular/common';

@Component({
  selector: 'app-comparison',
  templateUrl: './comparison.component.html',
  styleUrls: ['./comparison.component.css']
})
export class ComparisonComponent implements OnInit {
  showMainContent: Boolean = false;
   name ='Virtual Classroom';
    allvaluse ='';
    upgrade: Boolean = true;
    upgradenext1: Boolean = false;
    upgradenext2: Boolean = false;
  constructor(private httpClient: HttpClient,location: PlatformLocation) {
    location.onPopState(() => {

      console.log(this.showMainContent);
      if(this.showMainContent == true)
      {
        this.showMainContent = false;
        window.scrollTo(0, 450);
        return false;
      }

  });

   }

  ngOnInit() {
    window.scrollTo(0, 0);
    var  num:number = 0;
  this.httpClient.get("http://fs-dev.us-west-2.elasticbeanstalk.com/en/assets/json/compare.json").subscribe(data =>{
   //this.httpClient.get("../../assets/json/compare.json").subscribe(data =>{
      console.log(data);
      let arr = [];
        Object.keys(data).map(function(key){
            arr.push({[key]:data[key]})
            return arr;
        });
        //console.log(arr[0].Compare1.CourseFeatures);
        if(num==0)
        {
          this.name = arr[0].Compare1.CourseFeatures;
          this.allvaluse = arr[0].Compare1.values;
          this.upgradenext1 = arr[0].Compare1.upgrade1;
          this.upgradenext2 = arr[0].Compare1.upgrade2;
          
        }else if(num == 1)
        {
          this.name = arr[1].Compare2.CourseFeatures;
          this.allvaluse = arr[1].Compare2.values;
          this.upgradenext1 = arr[1].Compare2.upgrade1;
          this.upgradenext2 = arr[1].Compare2.upgrade2;
         
        }else{
          this.name = arr[2].Compare3.CourseFeatures;
          this.allvaluse = arr[2].Compare3.values;
          this.upgradenext1 = arr[2].Compare3.upgrade1;
          this.upgradenext2 = arr[2].Compare3.upgrade2;
          
        }
      
  })
}

  ShowHideButton(id:number) {
    
    this.showMainContent = this.showMainContent ? false : true;
    window.scrollTo(0, 450);
    var  num:number = id;
    
    if ( num < 4 )
    {
     this.httpClient.get("http://fs-dev.us-west-2.elasticbeanstalk.com/en/assets/json/compare.json").subscribe(data =>{
     //this.httpClient.get("../../assets/json/compare.json").subscribe(data =>{
        console.log(data);
        let arr = [];
        Object.keys(data).map(function(key){
            arr.push({[key]:data[key]})
            return arr;
        });
        //console.log(arr[0].Compare1.CourseFeatures);
        if(num==0)
        {
          this.name = arr[0].Compare1.CourseFeatures;
          this.allvaluse = arr[0].Compare1.values;
          this.upgradenext1 = arr[0].Compare1.upgrade1;
          this.upgradenext2 = arr[0].Compare1.upgrade2;
          
        }else if(num == 1)
        {
          this.name = arr[1].Compare2.CourseFeatures;
          this.allvaluse = arr[1].Compare2.values;
          this.upgradenext1 = arr[1].Compare2.upgrade1;
          this.upgradenext2 = arr[1].Compare2.upgrade2;
         
        }else{
          this.name = arr[2].Compare3.CourseFeatures;
          this.allvaluse = arr[2].Compare3.values;
          this.upgradenext1 = arr[2].Compare3.upgrade1;
          this.upgradenext2 = arr[2].Compare3.upgrade2;
          
        }
        
    })
    }
   
  }

  ShowUpgrade(id:number) {
    
   // this.showMainContent = this.showMainContent ? false : true;
   // window.scrollTo(0, 450);
    var  num:number = id;
    
    if ( num < 4 )
    {
   this.httpClient.get("http://fs-dev.us-west-2.elasticbeanstalk.com/en/assets/json/compare.json").subscribe(data =>{
   //this.httpClient.get("../../assets/json/compare.json").subscribe(data =>{
        console.log(data);
        let arr = [];
        Object.keys(data).map(function(key){
            arr.push({[key]:data[key]})
            return arr;
        });
        //console.log(arr[0].Compare1.CourseFeatures);
        if(num==0)
        {
          this.name = arr[0].Compare1.CourseFeatures;
          this.allvaluse = arr[0].Compare1.values;
          this.upgradenext1 = arr[0].Compare1.upgrade1;
          this.upgradenext2 = arr[0].Compare1.upgrade2;
          this.upgrade=true;
          
        }else if(num == 1)
        {
          this.name = arr[1].Compare2.CourseFeatures;
          this.allvaluse = arr[1].Compare2.values;
          this.upgradenext1 = arr[1].Compare2.upgrade1;
          this.upgradenext2 = arr[1].Compare2.upgrade2;
          this.upgrade=true;
         
        }else{
          this.name = arr[2].Compare3.CourseFeatures;
          this.allvaluse = arr[2].Compare3.values;
          this.upgradenext1 = arr[2].Compare3.upgrade1;
          this.upgradenext2 = arr[2].Compare3.upgrade2;
          this.upgrade=false;
          
        }
        
    })
    }
   
  }
}

