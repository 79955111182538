import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainComponent } from './layouts/main/main.component';
import { HomeComponent } from './pages/home/home.component';
import { AboutUsComponent } from './pages/about-us/about-us.component';
import { FinancialComponent } from './pages/financial/financial.component';
import { WorkplaceComponent } from './pages/workplace/workplace.component';
import { ContactComponent } from './pages/contact/contact.component';
import { TrainingComponent } from './pages/training/training.component';
import { MediaComponent } from './pages/media/media.component';
import { ComparisonComponent } from './pages/comparison/comparison.component';
import { VirtualClassroomComponent } from './pages/virtualclassroom/virtualclassroom.component';
import { LandingComponent } from './pages/landing/landing.component';
import { TechnologyComponent } from './pages/technology/technology.component';
import { PlatformComponent } from './pages/platform/platform.component';
import { OurglobalnetworkComponent } from './pages/ourglobalnetwork/ourglobalnetwork.component';
import { RegulatoryComplianceComponent } from './pages/regulatorycompliance/regulatoryCompliance.component';
import { ConsultingComponent } from './pages/consulting/consulting.component';
import { ReportsComponent } from './pages/reports/reports.component';
import { TradeComponent } from './pages/trade/trade.component';
import { ManufacturingComponent } from './pages/manufacturing/manufacturing.component';
import { MembershipComponent } from './pages/membership/membership/membership.component';
import { MembershipComparisonComponent } from './pages/membership/membership-comparison/membership-comparison.component';
import { CertificationComponent } from './pages/certification/certification.component';
import { CertficationdetailsComponent } from './pages/certficationdetails/certficationdetails.component';
import { TermsAndConditionsComponent } from './pages/terms-and-conditions/terms-and-conditions.component';
import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component';
import { GainingComponent } from './pages/gaining/gaining.component';
import { CertificationStandardsComponent } from './pages/certification-standards/certification-standards.component';
import { CertificationServicesComponent } from './pages/certification-services/certification-services.component';
import { CertificationBenefitComponent } from './pages/certification-benefit/certification-benefit.component';
import { CertificationHowItWorksComponent } from './pages/certification-how-it-works/certification-how-it-works.component';
import { CertificationMethodologyComponent } from './pages/certification-methodology/certification-methodology.component';
import { IndividualCertificationComponent } from './pages/individual-certification/individual-certification.component';
import { IndividualCertificationComparisonComponent } from './pages/individual-certification-comparison/individual-certification-comparison.component';
import { IndividualCertificationDetailsComponent } from './pages/individual-certification-details/individual-certification-details.component';
import { ExecutiveCoachingComponent } from './pages/executive-coaching/executive-coaching.component';
import { ViewFormComponent } from './pages/survey-forms/view-form/view-form.component';
import { Press1Component } from './pages/press/press-1/press-1.component';
const routes: Routes = [
  {
    path: '', component: MainComponent,
    children: [
      { path: 'forms', component:ViewFormComponent },
      // { path: 'forms', loadChildren: () => import('./pages/survey-forms/survey-forms.module').then(m => m.SurveyFormsModule) },
      { path: 'LandingPage', component: LandingComponent, data: { animation: 'LandingPage' } },
      { path: '', component: HomeComponent, data: { animation: 'HomePage' } },
      { path: 'about-us', component: AboutUsComponent, data: { animation: 'AboutPage' } },
      { path: 'financial', component: FinancialComponent, data: { animation: 'Financial' } },
      { path: 'workplace', component: WorkplaceComponent, data: { animation: 'workplace' } },
      { path: 'contact', component: ContactComponent, data: { animation: 'contact' } },
      { path: 'training', component: TrainingComponent, data: { animation: 'training' } },
      { path: 'media', component: MediaComponent, data: { animation: 'media' } },
      { path: 'comparison', component: ComparisonComponent, data: { animation: 'comparison' } },
      { path: 'virtualclassroom', component: VirtualClassroomComponent, data: { animation: 'virtualclassroom' } },
      { path: 'technology', component: TechnologyComponent, data: { animation: 'technology' } },
      { path: 'screening', component: PlatformComponent, data: { animation: 'platform' } },
      { path: 'our-global-network', component: OurglobalnetworkComponent, data: { animation: 'ourglobalnetwork' } },
      { path: 'regulatorycompliance', component: RegulatoryComplianceComponent, data: { animation: 'regulatorycompliance' } },
      { path: 'consulting', component: ConsultingComponent, data: { animation: 'consulting' } },
      { path: 'reports', component: ReportsComponent, data: { animation: 'reports' } },
      { path: 'trade', component: TradeComponent, data: { animation: 'trade' } },
      { path: 'manufacturing', component: ManufacturingComponent, data: { animation: 'manufacturing' } },
      { path: 'membership', component: MembershipComponent, data: { animation: 'membership' } },
      { path: 'membership-comparison', component: MembershipComparisonComponent, data: { animation: 'MembershipComparison' } },
      { path: 'certification', component: CertificationComponent, data: { animation: 'certification' } },
      { path: 'certficationdetails', component: CertficationdetailsComponent, data: { animation: 'certficationdetails' } },
      { path: 'terms-and-conditions', component: TermsAndConditionsComponent, data: { animation: 'TermsAndConditions' } },
      { path: 'privacy-policy', component: PrivacyPolicyComponent, data: { animation: 'privacypolicy' } },
      { path: 'gaining-market-entry', component: GainingComponent, data: { animation: 'gaining' } },
      { path: 'certification-standards', component: CertificationStandardsComponent, data: { animation: 'CertificationStandards' } },
      { path: 'certification-services', component: CertificationServicesComponent, data: { animation: 'CertificationServices' } },
      { path: 'certification-benefits', component: CertificationBenefitComponent, data: { animation: 'CertificationBenefit' } },
      { path: 'certification-how-it-works', component: CertificationHowItWorksComponent, data: { animation: 'CertificationHowItWorks' } },
      { path: 'certification-methodology', component: CertificationMethodologyComponent, data: { animation: 'CertificationMethodology' } },
      { path: 'individual-certification', component: IndividualCertificationComponent, data: { animation: 'IndividualCertificationComponent' } },
      { path: 'individual-certification-comparison', component: IndividualCertificationComparisonComponent, data: { animation: 'individualcertificationcomparison' } },
      { path: 'individual-certification-details', component: IndividualCertificationDetailsComponent, data: { animation: 'IndividualCertificationDetails' } },
      { path: 'executive-coaching', component: ExecutiveCoachingComponent, data: { animation: 'ExecutiveCoaching' } },
      { path: 'FSG-Refinitiv-Press-Release', component: Press1Component, data: { animation: 'FSGRefinitivPressRelease' } },
    ]
  },
  { path: '**', pathMatch: 'full', redirectTo: '' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }

