import { Component, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { slideInAnimation } from './app.animations';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  animations: [
    slideInAnimation
    // animation triggers go here
  ]
})
export class AppComponent implements OnInit {
  title = 'fs-home';


  ngOnInit() {
    let cc = window as any;
    cc.cookieconsent.initialise({
      palette: {
        popup: {
          background: "#003c70"
        },
        button: {
          background: "#AC145A",
          text: "#fff"
        }
      },
      position: "bottom-right",
      theme: "block",
      layout: 'basic-header',
      content: {
        header: 'Cookie Policy',
        message: "All THE TRONIE FOUNDATION&reg; websites use cookies to improve your online experience. They were placed on your computer when you launched this website. You can change your cookie settings through your browser.",
        dismiss: "Got it!",
        link: "Learn more",
        href: "dataprivacy",
        policy: "Cookie Policy"
      }
    });
  }

  prepareRoute(outlet: RouterOutlet) {
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData['animation'];
  }
}
