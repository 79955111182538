import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.css']
})
export class LandingComponent implements OnInit {
  
  memberPortalUrl = environment.memberPortalUrl;

  constructor(private router: Router) { }

  ngOnInit() {
    
  }
  siteClick(id:number) {
    var  num:number = id;
    localStorage.setItem('clickedSite', num.toString());
    this.router.navigateByUrl('/home-page');
  }

}
