import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-technology',
  templateUrl: './technology.component.html',
  styleUrls: ['./technology.component.css']
})
export class TechnologyComponent implements OnInit {
  memberPortalUrl = environment.memberPortalUrl;

  constructor() { }

  ngOnInit() {
    window.scrollTo(0, 0);
  }

}
