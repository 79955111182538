import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-membership',
  templateUrl: './membership.component.html',
  styleUrls: ['./membership.component.css']
})
export class MembershipComponent implements OnInit {
  valuesStand = [
    { id: 1, y: "2,500", m: "450" },
    { id: 2, y: "3,500", m: "650"  },
    { id: 3, y: "4,500", m: "825"  },
    { id: 4, y: "6,500", m: "1,200"  },
    { id: 5, y: "14,000", m: "2,566"  },
    { id: 6, y: "25,000", m: "4,600"  },
    { id: 7, y: "40,000", m: "7,350"  }
  ];
standyear='2,500';
standMonth='450';
valuesPro = [
  { id: 1, y: "3,125", m: "562.50" },
  { id: 2, y: "4,375", m: "812.50"  },
  { id: 3, y: "5,625", m: "1,031.25"  },
  { id: 4, y: "8,125", m: "1,500.00"  },
  { id: 5, y: "17,500", m: "3,207.50"  },
  { id: 6, y: "31,250", m: "5,750.00"  },
  { id: 7, y: "50,000", m: "9,187.50"  }
];
proyear='3,125';
proMonth='562.50';
  constructor( ) { }

  ngOnInit() {
    window.scrollTo(0, 0);
  }
  public onChangeStand(event): void {  // event will give you full breif of action
    const newVal = event.target.value;
    this.standyear=this.valuesStand[newVal].y;
    this.standMonth=this.valuesStand[newVal].m;
  }
  public onChangePro(event): void {  // event will give you full breif of action
    const newVal = event.target.value;
    this.proyear=this.valuesPro[newVal].y;
    this.proMonth=this.valuesPro[newVal].m;
  }
}
