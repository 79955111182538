import { Component, OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { Router } from '@angular/router';
import { Location } from "@angular/common";


@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {

  memberPortalUrl = environment.memberPortalUrl;
  public href: string = "";
  route: string;
  public siteValue: string = "";

  constructor(location: Location, router: Router) {

    router.events.subscribe(val => {
      if (location.path() != "") {
        this.route = location.path();
      } else {
        this.route = "";
      }
      this.href =this.route;
      this.siteValue=localStorage.getItem('clickedSite');
    });
    
   }

  ngOnInit() {
    this.href =this.route;
        
  }

  

}
