import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-executive-coaching-details',
  templateUrl: './executive-coaching.component.html',
  styleUrls: ['./executive-coaching.component.css']
})
export class ExecutiveCoachingComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    window.scrollTo(0, 0);
  }

}
