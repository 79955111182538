import { Component, Input, OnInit } from '@angular/core';
import * as Survey from "survey-angular";

@Component({
  selector: 'app-survey',
  templateUrl: './survey.component.html',
  styleUrls: ['./survey.component.css']
})
export class SurveyComponent implements OnInit {

  constructor() { }
  @Input()
  set json(value: object) {
    const surveyModel = new Survey.Model(value);
    Survey.SurveyWindowNG.render("surveyElement", {
      model: surveyModel,
      isExpanded: true
    });
  }

  ngOnInit() {}

}
