import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-certification-methodology',
  templateUrl: './certification-methodology.component.html',
  styleUrls: ['./certification-methodology.component.css']
})
export class CertificationMethodologyComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    window.scrollTo(0, 0);
  }

}
