import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-certification-how-it-works',
  templateUrl: './certification-how-it-works.component.html',
  styleUrls: ['./certification-how-it-works.component.css']
})
export class CertificationHowItWorksComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    window.scrollTo(0, 0);
  }

}
